import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../store/store";
import { Ingress, Page, SectionHeader } from "./Page";
import {
  Box,
  Grid,
  List,
  ListItem,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SidebarItemData } from "./SidebarItem";
import MuiMarkdown from "mui-markdown";
import React from "react";
import { Bookable } from "@api/types";
import CustomButton from "./Button";

const CourseDetail = () => {
  const { course_slug } = useParams<{ course_slug: string }>();
  const { courses, bookables } = useStore();

  const course = courses.find((course) => course.slug === course_slug);

  const bookablesForCourse = bookables.filter(
    (b) => b.course_id === course?.id
  );

  const coursesInCategory = courses.filter(
    (c) => c.category === course?.category
  );

  const navigate = useNavigate();

  const location = useLocation();
  const current_path = location.pathname;

  const sidebarItems: SidebarItemData[] | undefined =
    course !== undefined
      ? [
          {
            color: "#f8c797",
            label: "START",
            url: "/",
            back: true,
          },
          {
            color: "#fad9ba",
            label: course.category,
            url: `/courses/${course.categorySlug}`,
          },
          ...coursesInCategory.map((c) => ({
            color:
              `/courses/${c.categorySlug}/${c.slug}` === current_path
                ? "#fdecdc"
                : "#FFFFFF",
            label: c.title,
            url: `/courses/${c.categorySlug}/${c.slug}`,
          })),
        ]
      : undefined;

  return (
    <Page
      header="courses"
      title={course?.category}
      sidebar={sidebarItems}
      right_sidebar={
        <Box display={"flex"} gap={2} flexDirection={"column"}>
          <Skeleton variant="rounded">
            <Typography variant={"body1"} fontStyle={"italic"}>
              "En verkligt kvalitativ kurs med både teori och praktik."
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"} fontStyle={"italic"}>
              "Kändes som en privatlektion då man alltid fick mycket enskild
              feedback."
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"} fontStyle={"italic"}>
              "Desas tre dagar gjorde mer för mig än någon annan utbildning
              gjort någonsin."
            </Typography>
          </Skeleton>
        </Box>
      }
      breadcrumbs={[]}
    >
      {course ? (
        <>
          <Typography variant={"h2"}>{course.title}</Typography>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Antal deltagare
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{course.max_participants}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Förkunskaper
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{course.prerequisites}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Antal dagar
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{course.days}</Typography>
            </Grid>
          </Grid>
          <Ingress>{course.ingress}</Ingress>

          <SectionHeader title={"Om kursen"} />
          <MuiMarkdown>{course.about_content}</MuiMarkdown>

          <SectionHeader title={"Innehåll"} />
          <MuiMarkdown>{course.content}</MuiMarkdown>

          <SectionHeader title="Mer om utbildningen" />
          <MuiMarkdown>{course.more_about}</MuiMarkdown>

          <SectionHeader title="Vanliga frågor" />
          <MuiMarkdown>{course.faq}</MuiMarkdown>

          <Table sx={{ marginTop: "85px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Stad</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookablesForCourse
                .reduce((acc, bookable) => {
                  const venue = acc.find(
                    (v) => v.venue_id === bookable.venue_id
                  );
                  if (venue) {
                    venue.bookables.push(bookable);
                  } else {
                    acc.push({
                      venue_id: bookable.venue_id,
                      bookables: [bookable],
                    });
                  }
                  return acc;
                }, [] as { venue_id: number; bookables: Bookable[] }[])
                .map((group, groupIndex) => (
                  <React.Fragment key={group.venue_id}>
                    {group.bookables.map((bookable, bookableIndex) => {
                      const startDate = new Date(bookable.start_date);
                      const endDate = new Date(bookable.end_date);
                      const formattedDate = `${startDate.getDate()} - ${endDate.getDate()} ${startDate.toLocaleDateString(
                        "sv-SE",
                        { month: "long" }
                      )} ${startDate.getFullYear()}`;

                      return (
                        <TableRow key={bookable.id}>
                          <TableCell>
                            {bookableIndex === 0
                              ? bookable.venue?.name ?? <Skeleton />
                              : ""}
                          </TableCell>
                          <TableCell>{formattedDate}</TableCell>
                          <TableCell align="right">
                            <CustomButton
                              onClick={() => {
                                navigate(
                                  `/courses/${course.categorySlug}/${course.slug}/${bookable.id}`
                                );
                              }}
                            >
                              Boka
                            </CustomButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <>
          <Skeleton variant="rounded">
            <Typography variant={"h2"}>Praktisk retorik</Typography>
          </Skeleton>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Antal deltagare
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded">
                <Typography variant="body2">4-6</Typography>
              </Skeleton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Förkunskaper
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded">
                <Typography variant="body2">Inga</Typography>
              </Skeleton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"}>
                Antal dagar
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded">
                <Typography variant="body2">3 heldagar</Typography>
              </Skeleton>
            </Grid>
          </Grid>
          <Skeleton variant="rounded">
            <Ingress>
              Vill du också slipa på konsten att presentera, informera och
              argumentera? Då rekommenderar vi vår välbeprövade utbildning
              ”Praktiskt retorik” som vi sedan 2006 genomfört för mer än tusen
              nöjda kursdeltagare.
            </Ingress>
          </Skeleton>
          <SectionHeader title={"Om kursen"} />
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              Varför är kursen så omtyckt? Enligt kursdeltagarna handlar det
              främst om tre faktorer:
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              En liten grupp som ger dig mer plats. För att du ska få möjlighet
              att öva, få personlig respons, ställa frågor, diskutera och
              reflektera så mycket som möjligt är vi max sex deltagare på
              kursen. Det här är en av de absolut viktigaste aspekterna för våra
              kursdeltagare.
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              Utbildningen kopplas till skarpa situationer. Teori i alla ära,
              men under kursen kopplas alla övningar till situationer som är
              relevanta i just ditt arbete. Det här gör också att du behöver
              lägga ner lite tid på egen hand mellan kursdagarna för att
              förbereda övningar och verkligen ”gnugga in” det du lärt dig under
              kursen. Utdelningen blir så mycket större då.
            </Typography>
          </Skeleton>
          <Skeleton variant="rounded">
            <Typography variant={"body1"}>
              Vi tar dig från förberedelse till framträdande. Under trygga
              former lotsar vi dig genom allt du behöver tänka på, från
              förberedelser till framförande, för att lyckas föra fram ditt
              budskap och verkligen övertyga i olika situationer. Genomgångar
              varvas med flera övningar så att du får reflektera, testa, få
              feedback – och testa igen. 
            </Typography>
          </Skeleton>
          <SectionHeader title={"Innehåll"} />
          <List sx={{ listStyleType: "disc" }}>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Att skapa trovärdighet och intresse för din person och ditt ämne
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Vad lyssnarna minns och hur du kan arbeta för att de ska minnas
                det du vill
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Vad man bör tänka på i inledningen för att fånga och hålla kvar
                lyssnarnas uppmärksamhet
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Hur du kan engagera och inspirera lyssnarna
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Att arbeta med exempel och berättelser
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Hur du gör snabba och genomtänkta förberedelser för att nå fram
                till dina olika målgrupper
              </ListItem>
            </Skeleton>
            <Skeleton variant="rounded">
              <ListItem sx={{ display: "list-item" }}>
                Nervositet – hur du kan mildra den
              </ListItem>
            </Skeleton>
          </List>
          <SectionHeader title="Mer om utbildningen" />
          <SectionHeader title="Vanliga frågor" />
        </>
      )}
    </Page>
  );
};

export default CourseDetail;
