import React from "react";
import styled from "@emotion/styled";
import { Box, Skeleton } from "@mui/material";
import PageWidthContainer from "./PageWidthContainer";
import PageHeader, { PageHeaderConfigKey } from "./PageHeader";
import { Link } from "react-router-dom";
import SidebarItem, { SidebarItemData } from "./SidebarItem";

const RightSidebar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <Box width={247}>{children}</Box>;
};

export type Crumb = {
  title: string;
  url?: string;
};

export const SectionHeader: React.FC<{ title: string }> = ({ title }) => {
  return (
    <h3
      style={{
        fontSize: "16px",
        fontFamily: "Open Sans",

        color: "#222222",
        fontWeight: "normal",
        padding: "8px",
        borderBottom: "1px solid #f9d0a9",
      }}
    >
      {title}
    </h3>
  );
};

export const Ingress: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <p style={{ fontSize: "16px", marginBottom: "16px" }}>{children}</p>;
};

export const Page: React.FC<{
  title?: string;
  header?: PageHeaderConfigKey;
  sidebar?: SidebarItemData[];
  right_sidebar?: React.ReactNode;
  breadcrumbs?: Crumb[];
  children: React.ReactNode;
}> = ({ title, header, sidebar, right_sidebar, breadcrumbs, children }) => {
  const mockSidebarItems: SidebarItemData[] = [
    {
      color: "#f8c797",
      label: "START",
      url: "/courses",
      back: true,
    },
    {
      color: "#fad9ba",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#fdecdc",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#efefef",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#efefef",
      label: "Course Category",
      url: `/courses/url`,
    },
    {
      color: "#efefef",
      label: "Course Category",
      url: `/courses/url`,
    },
  ];

  return (
    <article>
      <PageWidthContainer>
        <Box display={"flex"} flexDirection={"column"} gap={0}>
          {header && <PageHeader config={header} title={title} />}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Box width={228}>
              {sidebar &&
                sidebar.length > 0 &&
                sidebar.map((i) => <SidebarItem {...i} />)}
              {sidebar &&
                sidebar.length === 0 &&
                mockSidebarItems.map((i) => (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{
                      backgroundColor: i.color,
                    }}
                  >
                    <SidebarItem {...i} />
                  </Skeleton>
                ))}
            </Box>
            <Box
              display="flex"
              flexDirection={"column"}
              flex={1}
              sx={{
                padding: "11px 37px",
              }}
            >
              {breadcrumbs && (
                <Box display={"flex"} flexDirection={"row"} gap={1}>
                  {breadcrumbs.length ? (
                    breadcrumbs.map((crumb, index) => (
                      <Link key={index} to={crumb.url || ""}>
                        {crumb.title}
                      </Link>
                    ))
                  ) : (
                    <>
                      <Skeleton variant="rectangular">
                        <Link to={""}>Kurser</Link>
                      </Skeleton>
                      <Skeleton variant="rectangular">
                        <Link to={""}>Öppna utbildningar</Link>
                      </Skeleton>
                      <Skeleton variant="rectangular">
                        <Link to={""}>Kursnamn</Link>
                      </Skeleton>
                    </>
                  )}
                </Box>
              )}
              {children}
            </Box>
            {right_sidebar && <RightSidebar>{right_sidebar}</RightSidebar>}
          </Box>
        </Box>
      </PageWidthContainer>
    </article>
  );
};
