import apiClient from "@api/client";
import { Bookable, Course, Venue } from "@feat/api/types";
import {
  Button,
  FormControl,
  FormHelperText,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingSelect from "../common/components/fields/LoadingSelect";
import { formatDateTime } from "@feat/common/utils/nice-date";

export const BookingForm: React.FC<{
  courses: Course[];
  venues: Venue[];
  bookables: Bookable[];
  loadingCourses: boolean;
  loadingVenues: boolean;
  loadingBookables: boolean;
  lockedBookable?: Bookable | null;
}> = ({
  courses,
  venues,
  bookables,
  loadingCourses,
  loadingVenues,
  loadingBookables,
  lockedBookable,
}) => {
  // Form value states
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [selectedBookable, setSelectedBookable] = useState<Bookable | null>(
    null
  );
  const [participants, setParticipants] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [invoice, setInvoice] = useState<string>("");
  const [food, setFood] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  // Field error states
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const [courseError, setCourseError] = useState<string | null>(null);
  const [venueError, setVenueError] = useState<string | null>(null);
  const [bookableError, setBookableError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  // Autoselect states
  const [courseWasAutoselected, setCourseWasAutoselected] = useState(false);
  const [venueWasAutoselected, setVenueWasAutoselected] = useState(false);
  const [bookableWasAutoselected, setBookableWasAutoselected] = useState(false);

  // Filtered states
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);
  const [filteredVenues, setFilteredVenues] = useState<Venue[]>([]);
  const [filteredBookables, setFilteredBookables] = useState<Bookable[]>([]);

  // Set selected course, venue and bookable if a locked bookable is provided
  useEffect(() => {
    if (lockedBookable && courses.length > 0 && venues.length > 0) {
      setSelectedBookable(lockedBookable);
      setBookableWasAutoselected(true);
      setSelectedCourse(
        courses.find((course) => course.id === lockedBookable.course_id) || null
      );
      setCourseWasAutoselected(true);
      setSelectedVenue(
        venues.find((venue) => venue.id === lockedBookable.venue_id) || null
      );
      setVenueWasAutoselected(true);
    }
  }, [bookables, courses, lockedBookable, venues]);

  useEffect(() => {
    setFilteredBookables((_) => {
      let filtered = bookables;
      if (selectedCourse && !courseWasAutoselected) {
        filtered = filtered.filter(
          (bookable) => bookable.course_id === selectedCourse.id
        );
      }
      if (selectedVenue && !venueWasAutoselected) {
        filtered = filtered.filter(
          (bookable) => bookable.venue_id === selectedVenue.id
        );
      }

      if (
        selectedBookable &&
        !filtered.some((bookable) => bookable.id === selectedBookable.id)
      ) {
        setSelectedBookable(null);
        setBookableWasAutoselected(false);
      }

      if (filtered.length === 1) {
        setSelectedBookable(filtered[0]);
        setBookableWasAutoselected(true);
      }

      return filtered;
    });

    setFilteredCourses((_) => {
      let filtered = courses;
      if (selectedVenue && !venueWasAutoselected) {
        const venueBookables = bookables.filter(
          (bookable) => bookable.venue_id === selectedVenue.id
        );
        filtered = filtered.filter((course) =>
          venueBookables.some((bookable) => bookable.course_id === course.id)
        );
      }
      if (selectedBookable && !bookableWasAutoselected) {
        filtered = filtered.filter(
          (course) => course.id === selectedBookable.course_id
        );
      }

      if (
        selectedCourse &&
        !filtered.some((course) => course.id === selectedCourse.id)
      ) {
        setSelectedCourse(null);
        setCourseWasAutoselected(false);
      }

      if (filtered.length === 1) {
        setSelectedCourse(filtered[0]);
        setCourseWasAutoselected(true);
      }

      return filtered;
    });

    setFilteredVenues((_) => {
      let filtered = venues;
      if (selectedCourse && !courseWasAutoselected) {
        const courseBookables = bookables.filter(
          (bookable) => bookable.course_id === selectedCourse.id
        );
        filtered = filtered.filter((venue) =>
          courseBookables.some((bookable) => bookable.venue_id === venue.id)
        );
      }
      if (selectedBookable && !bookableWasAutoselected) {
        filtered = filtered.filter(
          (venue) => venue.id === selectedBookable.venue_id
        );
      }

      if (
        selectedVenue &&
        !filtered.some((venue) => venue.id === selectedVenue.id)
      ) {
        setSelectedVenue(null);
        setVenueWasAutoselected(false);
      }

      if (filtered.length === 1) {
        setSelectedVenue(filtered[0]);
        setVenueWasAutoselected(true);
      }
      return filtered;
    });
  }, [
    selectedCourse,
    selectedVenue,
    selectedBookable,
    bookables,
    courses,
    venues,
    bookableWasAutoselected,
    courseWasAutoselected,
    venueWasAutoselected,
  ]);

  // Validation
  useEffect(() => {
    if (didTrySubmit && !selectedCourse) {
      setCourseError("Välj en kurs");
    } else {
      setCourseError(null);
    }
  }, [didTrySubmit, selectedCourse]);

  useEffect(() => {
    if (didTrySubmit && !selectedVenue) {
      setVenueError("Välj en stad");
    } else {
      setVenueError(null);
    }
  }, [didTrySubmit, selectedVenue]);

  useEffect(() => {
    if (didTrySubmit && !selectedBookable) {
      setBookableError("Välj ett tillfälle");
    } else {
      setBookableError(null);
    }
  }, [didTrySubmit, selectedBookable]);

  useEffect(() => {
    if (didTrySubmit && !name) {
      setNameError("Ange ditt namn");
    } else {
      setNameError(null);
    }
  }, [didTrySubmit, name]);

  useEffect(() => {
    if (didTrySubmit && !email) {
      setEmailError("Ange din e-postadress");
    } else if (didTrySubmit && !/^.+@.+\..+$/.test(email)) {
      setEmailError("Ange en giltig e-postadress");
    } else {
      setEmailError(null);
    }
  }, [didTrySubmit, email]);

  useEffect(() => {
    if (didTrySubmit && phone && !/^\d{10}$/.test(phone)) {
      setPhoneError("Ange ett giltigt telefonnummer");
    } else {
      setPhoneError(null);
    }
  }, [didTrySubmit, phone]);

  // Advanced input handlers
  const handleChangeCourse = (course: Course | null) => {
    setSelectedCourse(course);
    setCourseWasAutoselected(false);
  };

  const handleChangeVenue = (venue: Venue | null) => {
    setSelectedVenue(venue);
    setVenueWasAutoselected(false);
  };

  const handleChangeBookable = (bookable: Bookable | null) => {
    setSelectedBookable(bookable);
    setBookableWasAutoselected(false);
  };

  const handleClearCourse = () => {
    setSelectedCourse(null);
    setCourseWasAutoselected(false);

    if (venueWasAutoselected) {
      setSelectedVenue(null);
      setVenueWasAutoselected(false);
    }

    if (bookableWasAutoselected) {
      setSelectedBookable(null);
      setBookableWasAutoselected(false);
    }
  };

  const handleClearVenue = () => {
    setSelectedVenue(null);
    setVenueWasAutoselected(false);

    if (bookableWasAutoselected) {
      setSelectedBookable(null);
      setBookableWasAutoselected(false);
    }

    if (courseWasAutoselected) {
      setSelectedCourse(null);
      setCourseWasAutoselected(false);
    }
  };

  const handleClearBookable = () => {
    setSelectedBookable(null);
    setBookableWasAutoselected(false);

    if (courseWasAutoselected) {
      setSelectedCourse(null);
      setCourseWasAutoselected(false);
    }

    if (venueWasAutoselected) {
      setSelectedVenue(null);
      setVenueWasAutoselected(false);
    }
  };

  const handleChangeParticipants = (value: number) => {
    setParticipants(value);
  };
  const handleSubmit = () => {
    setDidTrySubmit(true);

    if (
      !selectedCourse ||
      !selectedVenue ||
      !selectedBookable ||
      !name ||
      !email
    ) {
      return;
    }

    // Validate email and phone number
    if (!/^.+@.+\..+$/.test(email)) {
      return;
    }

    if (phone && !/^\d{10}$/.test(phone)) {
      return;
    }

    // Send booking request
    apiClient
      .post("bookings", {
        bookable_id: selectedBookable.id,
        participants,
        name,
        email,
        phone,
        billing_details: invoice,
        food_preference: food,
        other_information: message,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to book course");
        }

        // Clear all fields
        setSelectedCourse(null);
        setSelectedVenue(null);
        setSelectedBookable(null);
        setParticipants(1);
        setName("");
        setEmail("");
        setPhone("");
        setInvoice("");
        setFood("");
        setMessage("");
        setDidTrySubmit(false);
        setCourseError(null);
        setVenueError(null);
        setBookableError(null);
        setNameError(null);
        setEmailError(null);
        setPhoneError(null);

        alert("Anmälan skickad!");
      })
      .catch((error) => {
        console.error(error);
        alert("Ett fel uppstod. Försök igen senare.");
      });
  };

  return (
    <Stack spacing={4}>
      <LoadingSelect
        loading={loadingCourses}
        clearable={selectedCourse !== null && !courseWasAutoselected}
        value={selectedCourse}
        options={filteredCourses}
        label="Kurs"
        error={courseError}
        onClear={handleClearCourse}
        onChange={handleChangeCourse}
        optionLabel={(course) => course.title}
        optionValue={(course) => `${course.id}`}
      />
      <LoadingSelect
        loading={loadingVenues}
        clearable={selectedVenue !== null && !venueWasAutoselected}
        value={selectedVenue}
        options={filteredVenues}
        label="Stad"
        error={venueError}
        onClear={handleClearVenue}
        onChange={handleChangeVenue}
        optionLabel={(venue) => venue.name}
        optionValue={(venue) => `${venue.id}`}
      />
      <LoadingSelect
        loading={loadingBookables}
        clearable={selectedBookable !== null && !bookableWasAutoselected}
        value={selectedBookable}
        options={filteredBookables}
        label="Tillfälle"
        error={bookableError}
        onClear={handleClearBookable}
        onChange={handleChangeBookable}
        optionLabel={(bookable) =>
          `${formatDateTime(bookable.start_date)} - ${formatDateTime(
            bookable.end_date
          )}`
        }
        optionValue={(bookable) => `${bookable.id}`}
      />
      <FormControl fullWidth>
        <Typography>Antal deltagare</Typography>
        <Slider
          valueLabelFormat={(value) => `${value} deltagare`}
          aria-label="Antal deltagare"
          value={participants}
          getAriaValueText={(value) => `${value} deltagare`}
          valueLabelDisplay={"auto"}
          shiftStep={1}
          step={1}
          marks={[
            {
              value: 1,
              label: 1,
            },
            {
              value: 10,
              label: "10+",
            },
          ]}
          min={1}
          max={10}
          onChange={(_, value) => handleChangeParticipants(value as number)}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="name"
          label="Namn"
          variant="outlined"
          fullWidth
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!nameError}
        />
        {nameError && <FormHelperText error>{nameError}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth>
        <TextField
          id="email"
          label="E-post"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
        />
        {emailError && <FormHelperText error>{emailError}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="phone"
          label="Telefon"
          variant="outlined"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          error={!!phoneError}
        />
        {phoneError && <FormHelperText error>{phoneError}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="invoice"
          label="Fakturaadress samt eventuellt referens/ordernummer"
          variant="outlined"
          fullWidth
          value={invoice}
          onChange={(e) => setInvoice(e.target.value)}
        />
        <FormHelperText>
          Fakturan skickas först efter att utbildningen är avslutad. Har du inte
          alla uppgifter nu kan du ändå anmäla dig och kompletera med
          fakturauppgifterna senare (men före kursstart).
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="food"
          label="Matpreferenser/allergier"
          variant="outlined"
          multiline
          fullWidth
          rows={4}
          value={food}
          onChange={(e) => setFood(e.target.value)}
        />
        <FormHelperText>
          Om det är något vi bör veta gällande vad du vill/kan äta så ange det
          här.
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="message"
          label="Övrigt"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <FormHelperText>
          Om det är något annat du vill meddela oss så ange det här.
        </FormHelperText>
      </FormControl>
      <p>
        Genom att skicka in min anmälan samtycker jag till att
        Retorikutbildning.se Sverige AB sparar och hanterar mina uppgifter för
        att kunna handlägga min anmälan. Uppgifterna delas aldrig med andra
        företag och du kommer inte att få nyhetsbrev.
      </p>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Skicka anmälan
      </Button>
    </Stack>
  );
};
