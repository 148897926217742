import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

type LoadingSelectProps<T> = {
  loading: boolean;
  clearable?: boolean;
  value: T | null; // The current selected value
  options: T[];
  label: string;
  help?: string;
  error: string | null;
  onClear: () => void; // Handler for when the selection is cleared
  onChange: (value: T | null) => void; // Handler for when the selection changes
  optionLabel: (option: T) => string; // Function to get string representation of an option
  optionValue: (option: T) => string; // Function to get value representation of an option
};

const LoadingSelect = <T,>({
  loading,
  clearable = false,
  value,
  options,
  label,
  help,
  error,
  onClear,
  onChange,
  optionLabel,
  optionValue,
}: LoadingSelectProps<T>) => {
  // Function to find the option based on value, necessary for setting the correct initial value in Select

  return (
    <>
      {loading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton variant="rectangular" height={56} width={"100%"} />
          {clearable && (
            <Skeleton variant="rectangular" height={56} width={56} />
          )}
        </Stack>
      ) : (
        <FormControl fullWidth>
          <Stack direction="row" spacing={2}>
            <Box width={"100%"}>
              <TextField
                label={label}
                value={value ? optionValue(value) : ""}
                select
                onChange={(e) => {
                  const value = options.find(
                    (option) => optionValue(option) === e.target.value
                  );
                  if (value) onChange(value);
                }}
                fullWidth
                error={!!error}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={optionValue(option)}>
                    {optionLabel(option)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {clearable && (
              <IconButton
                onClick={() => {
                  onClear();
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Stack>
          {error && <FormHelperText error>{error}</FormHelperText>}
          {help && <FormHelperText>{help}</FormHelperText>}
        </FormControl>
      )}
    </>
  );
};

export default LoadingSelect;
