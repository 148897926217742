import { Page } from "@comp/Page";
import { BookingForm } from "./BookingForm";
import { useStore } from "@feat/common/store/store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Bookable } from "@api/types";

export const Booking = () => {
  const {
    courses,
    fetchingCourses,
    venues,
    fetchingVenues,
    bookables,
    fetchingBookables,
  } = useStore();

  const { bookable_id: bookableId } = useParams<{ bookable_id: string }>();
  const [lockedBookable, setLockedBookable] = useState<Bookable | null>(null);

  useEffect(() => {
    if (bookableId && bookables.length > 0) {
      const bookable = bookables.find(
        (bookable) => bookable.id === parseInt(bookableId)
      );
      if (bookable) {
        setLockedBookable(bookable);
      }
    }
  }, [bookableId, bookables]);

  return (
    <Page title="Boka Kurs">
      {fetchingCourses || fetchingVenues || fetchingBookables ? (
        <p>Laddar...</p>
      ) : courses.length === 0 ? (
        <p>Inga kurser att boka</p>
      ) : lockedBookable || !bookableId ? (
        <BookingForm
          courses={courses}
          venues={venues}
          bookables={bookables}
          loadingCourses={fetchingCourses}
          loadingVenues={fetchingVenues}
          loadingBookables={fetchingBookables}
          lockedBookable={lockedBookable}
        />
      ) : (
        <p>Kunde inte hitta kursen att boka</p>
      )}
    </Page>
  );
};
