// src/colors.ts

const colors = {
  gray: {
    900: "#212121",
    // Add more shades as needed
  },
  orange: {
    300: "#FFB74D",
    // Add more shades as needed
  },
  accent: {
    900: "#1A237E",
    // Add more shades as needed
  },
  indigo: {
    700: "#303F9F",
    // Add more shades as needed
  },
};

export default colors;
