import { StateCreator } from "zustand";
import { Venue } from "@api/types";

export type VenueWithSlug = Venue & {
  slug: string;
};

export interface VenuesState {
  fetchingVenues: boolean;
  venues: VenueWithSlug[];
  hasFetchedVenues: boolean;
  setFetchingVenues: (fetchingVenues: boolean) => void;
  setVenues: (venues: Venue[]) => void;
  setVenue: (venue: Venue) => void;
  removeVenue: (id: number) => void;
}

const slugify = (str: string): string => {
  const convertSwedishCharacters = (str: string): string => {
    return str
      .replace(/å/g, "a")
      .replace(/ä/g, "a")
      .replace(/ö/g, "o")
      .replace(/Å/g, "a")
      .replace(/Ä/g, "a")
      .replace(/Ö/g, "o");
  };
  return convertSwedishCharacters(str).toLowerCase().replace(/ /g, "-");
};

const addSlug = (venue: Venue): VenueWithSlug => ({
  ...venue,
  slug: slugify(venue.name),
});

export const venuesSlice: StateCreator<VenuesState> = (set) => ({
  fetchingVenues: false,
  venues: [],
  hasFetchedVenues: false,
  setFetchingVenues: (fetchingVenues) => set({ fetchingVenues }),
  setVenues: (venues) =>
    set({
      venues: venues.map(addSlug),
      hasFetchedVenues: true,
    }),
  setVenue: (venue) => {
    const venueWithSlug = addSlug(venue);
    set((state) => {
      const existingVenue = state.venues.find((v) => v.id === venueWithSlug.id);
      if (existingVenue) {
        return {
          venues: state.venues.map((v) =>
            v.id === venueWithSlug.id ? venueWithSlug : v
          ),
        };
      } else {
        return {
          venues: [...state.venues, venueWithSlug],
        };
      }
    });
  },
  removeVenue: (id) =>
    set((state) => ({
      venues: state.venues.filter((v) => v.id !== id),
    })),
});
