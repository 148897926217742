import { StateCreator } from "zustand";
import { Course } from "@api/types";

export type CourseWithSlugs = Course & {
  slug: string;
  categorySlug: string;
};

export interface CoursesState {
  fetchingCourses: boolean;
  courses: CourseWithSlugs[];
  hasFetchedCourses: boolean;
  setFetchingCourses: (fetchingCourses: boolean) => void;
  setCourses: (courses: Course[]) => void;
  setCourse: (course: Course) => void;
  removeCourse: (id: number) => void;
}

const slugify = (str: string): string => {
  const convertSwedishCharacters = (str: string): string => {
    return str
      .replace(/å/g, "a")
      .replace(/ä/g, "a")
      .replace(/ö/g, "o")
      .replace(/Å/g, "a")
      .replace(/Ä/g, "a")
      .replace(/Ö/g, "o");
  };
  return convertSwedishCharacters(str).toLowerCase().replace(/ /g, "-");
};

const addSlug = (course: Course): CourseWithSlugs => ({
  ...course,
  slug: slugify(course.title),
  categorySlug: slugify(course.category),
});

export const coursesSlice: StateCreator<CoursesState> = (set) => ({
  fetchingCourses: false,
  courses: [],
  hasFetchedCourses: false,
  setFetchingCourses: (fetchingCourses) => set({ fetchingCourses }),
  setCourses: (courses) =>
    set({
      courses: courses.map(addSlug),
      hasFetchedCourses: true,
    }),
  setCourse: (course) => {
    const courseWithSlug = addSlug(course);
    set((state) => {
      const existingCourse = state.courses.find(
        (c) => c.id === courseWithSlug.id
      );
      if (existingCourse) {
        return {
          courses: state.courses.map((c) =>
            c.id === courseWithSlug.id ? courseWithSlug : c
          ),
        };
      } else {
        return {
          courses: [...state.courses, courseWithSlug],
        };
      }
    });
  },
  removeCourse: (id) => {
    set((state) => ({
      courses: state.courses.filter((c) => c.id !== id),
    }));
  },
});
