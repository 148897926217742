import { ReactComponent as LogoSvg } from "@assets/logo.svg";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { ArrowDropDown, Menu as Hamburger } from "@mui/icons-material";
import Search from "./Search";
import gray from "src/colors/gray";
import PageWidthContainer from "./PageWidthContainer";
import theme from "src/theme";
import { CourseWithSlugs } from "@feat/common/store/coursesSlice";
import { useStore } from "@feat/common/store/store";

const CoursesMenu: React.FC<{
  courses: CourseWithSlugs[];
  nested?: boolean;
}> = ({ courses, nested }) => {
  const categories: { name: string; courses: CourseWithSlugs[] }[] =
    courses.reduce((acc, course) => {
      const category = acc.find(
        (c: { name: string; courses: CourseWithSlugs[] }) =>
          c.name === course.category
      );
      if (category) {
        category.courses.push(course);
      } else {
        acc.push({ name: course.category, courses: [course] });
      }
      return acc;
    }, [] as { name: string; courses: CourseWithSlugs[] }[]);

  const coursesMenu = (
    <Stack
      direction={nested ? "column" : "row"}
      gap={2}
      flexGrow={1}
      justifyContent={"center"}
    >
      {categories.map((category) => (
        <CoursesMenuCategory key={category.name} name={category.name}>
          {category.courses.map((course) => (
            <CoursesMenuCourse
              key={course.id}
              name={course.title}
              url={`/courses/${course.categorySlug}/${course.slug}`}
            />
          ))}
        </CoursesMenuCategory>
      ))}
    </Stack>
  );

  return nested ? (
    <PopupState variant="popover" popupId="course-main-menu">
      {(popupState) => (
        <div>
          <Button
            variant="text"
            endIcon={<ArrowDropDown htmlColor={gray[900]} />}
            {...bindTrigger(popupState)}
          >
            <Box color={gray[800]}>Utbildningar</Box>
          </Button>
          <Menu
            disableScrollLock
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {coursesMenu}
          </Menu>
        </div>
      )}
    </PopupState>
  ) : (
    coursesMenu
  );
};

const CoursesMenuCategory: React.FC<{
  name: string;
  children: React.ReactNode;
}> = ({ name, children }) => {
  return (
    <PopupState variant="popover" popupId={`course-menu-${name}`}>
      {(popupState) => (
        <div>
          <Button
            variant="text"
            fullWidth
            endIcon={<ArrowDropDown htmlColor={gray[900]} />}
            style={{ justifyContent: "space-between" }}
            {...bindTrigger(popupState)}
          >
            <Box
              color={gray[800]}
              sx={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {name}
            </Box>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {children}
          </Menu>
        </div>
      )}
    </PopupState>
  );
};

const CoursesMenuCourse: React.FC<{ name: string; url: string }> = ({
  name,
  url,
}) => {
  return (
    <MenuItem color={gray[800]} component={Link} href={url}>
      {name}
    </MenuItem>
  );
};

interface HeaderProps {
  pages: { name: string; url: string }[];
}

export const Header = ({ pages }: HeaderProps) => {
  const { courses, fetchingCourses } = useStore();

  const wide = useMediaQuery(theme.breakpoints.up("md"));
  const hamburger = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <header>
      <PageWidthContainer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          width={"100%"}
          height={wide ? 120 : 80}
          paddingY={wide ? 2 : 1}
        >
          <Box width={wide ? 193 : "auto"} height={wide ? "auto" : "90%"}>
            <RouterLink to="/">
              <LogoSvg
                width={wide ? 193 : "auto"}
                height={wide ? "auto" : "90%"}
              />
            </RouterLink>
          </Box>
          {fetchingCourses ? (
            <CircularProgress />
          ) : (
            courses && <CoursesMenu courses={courses} nested={!wide} />
          )}
        </Stack>
      </PageWidthContainer>
      <div
        style={{
          backgroundColor: gray[50],
          borderTop: `1px solid ${gray[100]}`,
        }}
      >
        <PageWidthContainer>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            gap={2}
            alignItems="center"
            height={43}
          >
            {hamburger ? (
              <PopupState variant="popover" popupId="bottom-menu">
                {(popupState) => (
                  <>
                    <IconButton {...bindTrigger(popupState)}>
                      <Hamburger />
                    </IconButton>
                    <Menu
                      {...bindMenu(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem>
                        <Search />
                      </MenuItem>
                      {pages.map((page) => (
                        <MenuItem key={page.url}>
                          <Link
                            href={page.url}
                            style={{
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                              fontSize: 14,
                              textDecoration: "none",
                              color: "#626262",
                            }}
                          >
                            {page.name}
                          </Link>
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </PopupState>
            ) : (
              <>
                {pages.map((page) => (
                  <Link
                    key={page.url}
                    href={page.url}
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      fontSize: 14,
                      textDecoration: "none",
                      color: "#626262",
                    }}
                  >
                    {page.name}
                  </Link>
                ))}
                <Search />
              </>
            )}
          </Stack>
        </PageWidthContainer>
      </div>
    </header>
  );
};
