import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export type SidebarItemData = {
  color: string;
  label: string;
  url?: string;
  onClick?: () => void;
  back?: boolean;
  large?: boolean;
};

const SidebarLink = styled(Link)`
  display: block;
  padding: 12px 24px;
  color: #fff;
  text-decoration: none;
  background-color: ${(props: SidebarItemData) => props.color};
  font-family: "Open Sans", sans-serif;
  font-size: ${(props: SidebarItemData) => (props.large ? "14px" : "12px")};
  color: #222222;
  &::before {
    content: ${(props: SidebarItemData) => (props.back ? "'\u00AB '" : "''")};
  }
`;

const SidebarItem: React.FC<SidebarItemData> = ({
  label,
  color,
  url,
  onClick,
  back,
  large,
}) => {
  return (
    <SidebarLink
      to={url ?? ""}
      label={label}
      color={color}
      url={url}
      onClick={onClick}
      back={back}
      large={large}
    >
      <span>{label}</span>
    </SidebarLink>
  );
};

export default SidebarItem;
