import { useParams } from "react-router-dom";
import { useStore } from "../store/store";
import { useEffect, useState } from "react";
import { CourseWithSlugs } from "../store/coursesSlice";

const CourseCategory = () => {
  // Get course category slug from :category
  const { category_slug } = useParams<{ category_slug: string }>();
  const { courses, fetchingCourses, hasFetchedCourses } = useStore();
  const [coursesInCategory, setCoursesInCategory] = useState<CourseWithSlugs[]>(
    []
  );

  useEffect(() => {
    if (courses.length > 0) {
      const coursesInCategory = courses.filter(
        (course) => course.category === category_slug
      );
      setCoursesInCategory(coursesInCategory);
    } else {
      console.log("No courses", courses, fetchingCourses, hasFetchedCourses);
    }
  }, [category_slug, courses, hasFetchedCourses]);

  return (
    <div>
      <h1>{coursesInCategory.length > 0 && coursesInCategory[0].category}</h1>
    </div>
  );
};

export default CourseCategory;
