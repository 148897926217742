import { Box } from "@mui/material";
import { ReactComponent as CourseHeaderLeft } from "@assets/course_header_left.svg";
import { ReactComponent as CourseHeaderRight } from "@assets/course_header_right.svg";
import { ReactComponent as CourseHeaderTitleDecor } from "@assets/course_header_title_decor.svg";
import styled from "@emotion/styled";

export type PageHeaderConfigKey = keyof typeof headers;

const headers: {
  [key: string]: {
    left: React.ReactNode;
    right: React.ReactNode;
    title_decor: React.ReactNode;
    background: string;
  };
} = {
  courses: {
    left: <CourseHeaderLeft width={165} height={136} />,
    right: <CourseHeaderRight />,
    title_decor: <CourseHeaderTitleDecor />,
    background: "#F08A27CD",
  },
  blank: {
    left: null,
    right: null,
    title_decor: null,
    background: "#F08A27CD",
  },
};

const PageTitle = styled.h2`
  font-size: 32px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
`;

const PageHeader: React.FC<{
  title?: string;
  config: keyof typeof headers;
}> = ({ title, config }) => {
  const { left, right, title_decor, background } = headers[config];
  return (
    <Box
      display="flex"
      sx={{ background: background }}
      justifyContent="space-between"
      alignContent={"center"}
    >
      {left}
      {title && (
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column">
            <PageTitle>{title}</PageTitle>
          </Box>
          {title_decor}
        </Box>
      )}
      {right}
    </Box>
  );
};

export default PageHeader;
