import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";

interface CustomButtonProps extends ButtonProps {
  primary?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { primary, variant, children, ...rest } = props;
  const buttonProps: ButtonProps = {
    ...rest,
    variant: primary ? "contained" : variant ?? "outlined",
    style: {
      borderRadius: 10,
      backgroundColor: primary ? "#017D85" : "transparent",
      borderWidth: 1,
      borderColor: "#006D73",
      color: primary ? "white" : "#004F53",
      fontStyle: "normal",
      textTransform: "none",
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "10px",
      minWidth: "50px",
      maxHeight: "15px",
    },
  };
  return <Button {...buttonProps}>{children}</Button>;
};

export default CustomButton;
